.PayRates .lander {
  padding: 80px 0;
  text-align: center;
}

.PayRates .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.PayRates .table {
  padding: 80px 0;
  text-align: center;
}