@media all and (min-width: 480px) {
  .Donate {
    padding: 60px 0;
  }

  .Donate form {
    margin: 0 auto;
    max-width: 480px;
  }
}
