.Items form textarea {
  height: 300px;
  font-size: 1.5rem;
}

.container .row {
  margin-bottom: 1rem;
}

.container .row > [class^="col"] {
  padding-right: 0;
  padding-left: 0;
}

@media all and (min-width: 480px) {
  .Items {
    padding: 60px 0;
  }

  .Items form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Items select {
    margin: 0 auto;
    max-width: 320px;
  }
}