.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  .Expense .table {
    padding: 80px 0;
    text-align: center;
  }

  .container .row {
    margin-bottom: 1rem;
  }
  
  .container .row > [class^="col"] {
    padding-right: 0;
    padding-left: 0;
  }

  .col-md-4 {
    flex: 0 0 33.3333333%;
    max-width: 33.3333333%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: flex;
}

.col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}

.ag-center-cols-clipper {
  min-height: unset !important;
}

.ag-header-cell-label {
  justify-content: center;
}