.App {

}

.navbar {
    margin-bottom: 0 !important;
    border: 0 !important;
    width:auto;
}

/* This file updates CSS to all child components */
.ag-theme-alpine {
    --ag-grid-size: 4px;
    --ag-list-item-height: 16px;
}