.Overview .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Overview .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  .Overview .table {
    padding: 80px 0;
    text-align: center;
  }

  .react-bootstrap-table table {
    table-layout: auto;
    size: sm
  }

  .underline {
    border-bottom-width: 4px;
   }

   aa {
     border-top: 1px solid black;
     padding-top: 1px;
     border-bottom: 1px solid black;
     padding-bottom: 1px;
   }

   .date-left {
    border-right: 1px dashed #333;
  }

  header-underline {
    border-bottom: 1px solid black;
  }